export type SortProps = {
  oldIndex: number;
  newIndex: number;
  items: any[];
};

export const menuItemSorting = <T extends SortProps>({
  items,
  oldIndex,
  newIndex,
}: T) => {
  const item = items[oldIndex];
  const newSort = [...items];

  if (oldIndex > newIndex) {
    newSort.splice(oldIndex, 1);
    newSort.splice(newIndex, 0, item);
  } else {
    newSort.splice(newIndex + 1, 0, item);
    newSort.splice(oldIndex, 1);
  }

  return newSort;
};

export default menuItemSorting;
