import React from 'react';

import { Col, Row } from 'common/components/Layout';
import Text from 'common/components/Text';

import NotificationCount from '../NotificationCount';

import { Wrapper } from './styles';

interface Props {
  count: number;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
}

const FilterBox = ({ count, label, isActive, onClick }: Props) => {
  return (
    <Wrapper isActive={isActive} onClick={onClick}>
      <Row alignItems="baseline" justifyContent="space-between">
        <Col marginL="xs">
          <Text color="emperor">{label}</Text>
        </Col>
        <NotificationCount value={count} size={24} />
      </Row>
    </Wrapper>
  );
};

export default FilterBox;
