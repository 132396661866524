import React from 'react';
import { createPortal } from 'react-dom';

import usePortal from 'common/utils/portal';

interface Props {
  id: string;
}

const Portal: React.FC<Props> = ({ id, children }) => {
  const target = usePortal(id);

  // tslint:disable-next-line
  return createPortal(children, target);
};

export default Portal;
