import React, { Suspense, useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import CommonRouter from 'common';
import DeveloperRouter from 'developer';
import KDSRouter from 'facility';
import ManagerRouter from 'manager';

import { AuthContext } from 'authContext';
import Loader from 'common/components/Loader';

export const Router = () => {
  const { loading } = useContext(AuthContext);

  if (loading) {
    return <Loader fullScreen />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader fullScreen />}>
        <Switch>
          <Route path="/developer">
            <DeveloperRouter />
          </Route>
          <Route path="/manager">
            <ManagerRouter />
          </Route>
          <Route path="/facility">
            <KDSRouter />
          </Route>
          <Route path="/">
            <CommonRouter />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
