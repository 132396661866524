import React, { FC } from 'react';

import Text from 'common/components/Text';
import { themeColors } from 'common/theme/constants';

import { ErrorMessage } from './styles';

interface Props {
  message: string;
}

const Error: FC<Props> = ({ message }) => {
  return (
    <ErrorMessage>
      <Text color={themeColors.primaryRed} fontSize="xs">
        {message}
      </Text>
    </ErrorMessage>
  );
};

export default Error;
