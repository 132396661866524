import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import {
  KitchenMenuItemStatus,
  useKitchenQuery,
  KitchenMenuItem,
  useSortKitchenItemsMutation,
} from 'codegen/generated/graphql';
import Loader from 'common/components/Loader';
import KitchenLayout from 'manager/components/KitchenLayout';
import ActiveOrders from 'manager/containers/RestaurantAdministration/Restaurants/ActiveOrders';
import KitchenForm from 'manager/containers/RestaurantAdministration/Restaurants/KitchenForm';
import MenuList from 'manager/containers/RestaurantAdministration/Restaurants/MenuList';
import { Props as SortProps } from 'manager/containers/RestaurantAdministration/Restaurants/MenuList/menuItemSorting';
import { Props, Type } from 'manager/pages/kitchen';
import menuItemSorting from 'manager/utils/dragAndDrop/sorting';

const KitchenUpdate: React.FC<Props> = ({ type }) => {
  const { kitchenId: id } = useParams<{ kitchenId: string }>();
  const [sortedItems, setSortedItems] = useState<KitchenMenuItem[]>([]);

  const [sortKitchenItems] = useSortKitchenItemsMutation();
  const { data, loading } = useKitchenQuery({
    fetchPolicy: 'network-only',
    variables: {
      kitchenId: id,
    },
  });

  useEffect(() => {
    const filtered =
      data?.adminKitchen?.kitchenMenuItems.filter(
        (item) => item?.status !== KitchenMenuItemStatus.Deleted,
      ) || [];
    const sorted = filtered.sort(
      (a, b) => (a.sortId || 0) - (b.sortId || 0),
    ) as KitchenMenuItem[];
    setSortedItems(sorted);
  }, [data]);

  const updateSorting = useCallback(
    ({ oldIndex, newIndex }: SortProps) => {
      const newSortedItems = menuItemSorting<SortProps>({
        items: sortedItems,
        oldIndex,
        newIndex,
      });

      setSortedItems(newSortedItems);

      sortKitchenItems({
        variables: {
          input: newSortedItems.map((e) => e.id),
        },
      });
    },
    [sortKitchenItems, sortedItems],
  );

  if (loading) return <Loader />;

  return (
    <KitchenLayout type={type} name={data?.adminKitchen?.name ?? ''}>
      {type === Type.Profile && data?.adminKitchen && (
        <KitchenForm kitchen={data.adminKitchen} />
      )}
      {type === Type.Menu && (
        <MenuList
          kitchenId={id}
          items={sortedItems}
          showCategory
          onChange={updateSorting}
        />
      )}
      {type === Type.ActiveOrders && <ActiveOrders kitchenId={id} />}
    </KitchenLayout>
  );
};

export default KitchenUpdate;
