import styled from 'styled-components';

import { Col, Row } from 'common/components/Layout';
import { NAVBAR_HEIGHT } from 'common/components/Navbar/styles';
import { position, zIndex } from 'common/utils/styleHelpers';

/**
 * Needs to have all height of page
 */
export const Container = styled(Row)`
  height: 100%;
`;

/**
 * While scrolling navbar should stay in place
 */
export const SideBarPanel = styled(Col)(
  () => `
  ${position('fixed')}
  ${zIndex('navbar')}
  height: 100%;
  padding-top: ${NAVBAR_HEIGHT};
`,
);

/**
 * Compansating navbar's width
 * with margin-left attribute
 */

export const ContentPanelIndex = styled(Col)`
  flex: 1;
`;

export const LeftActionDivider = styled.div`
  position: relative;
  min-height: 48px;
  display: flex;
  padding-left: 60px;

  ::before {
    position: absolute;
    content: '';
    border-left: 1px solid rgba(48, 30, 53, 0.1);
    width: 0;
    height: 38px;
    top: 5px;
    left: 30px;
  }
`;
