import React, { FunctionComponent } from 'react';

import { IconName } from 'common/components/Icon/types';

import { Wrapper, MenuIcon, IconButtonWrapperProps } from './styles';

interface Props extends IconButtonWrapperProps {
  name: IconName;
  onClick?: () => void;
  'aria-label'?: string;
  'data-cy'?: string;
}

const Button: FunctionComponent<Props> = ({
  name,
  size = 'normal',
  active = false,
  onClick,
  color,
  activeColor,
  'data-cy': dataCypress,
  ...rest
}) => (
  <Wrapper
    active={active}
    size={size}
    onClick={onClick}
    aria-label={rest['aria-label']}
    data-cy={dataCypress}
    {...rest}
  >
    <MenuIcon
      className="material-icons"
      active={active}
      size={size}
      color={color}
      activeColor={activeColor}
    >
      {name}
    </MenuIcon>
  </Wrapper>
);

export default Button;
