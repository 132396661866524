export const enumValueToReadable = (value: string) => {
  const lowerCased = value.replaceAll('_', ' ').toLowerCase();

  return lowerCased[0].toUpperCase() + lowerCased.slice(1);
};

//formats phone number input into (xxx) xxx-xxxx format
export const formatPhoneNumber = (value: string) => {
  if (!value) {
    return value;
  }

  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 4) {
    return currentValue;
  }

  if (cvLength < 7) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  }

  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6,
  )}-${currentValue.slice(6, 10)}`;
};
