import React from 'react';

import { DividerProps } from 'antd';

import { theme } from 'common/theme';
import { SpacingType } from 'common/utils/styleHelpers';

import { DividerStyle } from './styles';

interface Props extends DividerProps {
  color?: string;
  height?: number;
  width?: string;
  marginT?: SpacingType;
  marginB?: SpacingType;
}
/**
 * Divider - from Ant Design
 *
 * @param color - type of Color (default mischka)
 * @param height - number (default 1)
 * @param width - string (default 100%)
 * @param marginT - SpacingType (default sm = 12px)
 * @param marginB - SpacingType (default sm = 12px)
 */
const Divider = ({
  color = theme.color.mischka,
  height = 1,
  width = '100%',
  marginT = 'sm',
  marginB = 'sm',
  ...props
}: Props) => (
  <DividerStyle
    color={color}
    height={height}
    width={width}
    marginTop={marginT}
    marginBottom={marginB}
    {...props}
  />
);

export default Divider;
