import React from 'react';

import { Col, Row } from 'common/components/Layout';
import Text from 'common/components/Text';
import { themeColors } from 'common/theme/constants';

import { HeaderBox, ImageCover, InfoBox, KitchenTag } from './styles';

export interface Props {
  image: string;
  tags: string[];
  name: string;
  numbers: string[];
  infos: string[];
}

const TopBanner = ({ image, tags, name, numbers, infos }: Props) => (
  <Row>
    <ImageCover image={image}>
      <Row paddingX={52}>
        {tags.map((tag, i) => (
          <Col key={i}>
            <KitchenTag>
              <Text fontSize="small" fontWeight="semiBold">
                {tag}
              </Text>
            </KitchenTag>
          </Col>
        ))}
      </Row>
      <Row paddingX={52}>
        <Col>
          <HeaderBox>
            <Text
              fontWeight="semiBold"
              fontSize="h3"
              color={themeColors.secondaryText}
            >
              {name}
            </Text>
          </HeaderBox>
        </Col>
      </Row>
      <Row paddingX={52}>
        <Col>
          <InfoBox>
            {infos.map((info, i) => (
              <Text color={themeColors.secondaryText} fontSize="h3" key={info}>
                <span>{numbers[i]}</span>
                {info}
              </Text>
            ))}
          </InfoBox>
        </Col>
      </Row>
    </ImageCover>
  </Row>
);

export default TopBanner;
