import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { AuthContext } from 'authContext';
import { AuthenticatedUserRedirect } from 'router/AuthenticatedUserRedirect';

export default (props: RouteProps) => {
  const { user } = useContext(AuthContext);

  if (user) {
    return <AuthenticatedUserRedirect user={user} />;
  }

  return <Route {...props} />;
};
