import React, { useContext } from 'react';

import ErrorModalComponent from 'facility/components/ErrorModal';
import { ErrorContext } from 'facility/hooks/errorContext';

const ErrorModal = () => {
  const { error, setError } = useContext(ErrorContext);

  return (
    <ErrorModalComponent
      visible={Boolean(error)}
      message={error}
      onClose={() => setError('', '')}
    />
  );
};

export default ErrorModal;
