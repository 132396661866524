import cuid from 'cuid';
import moment from 'moment';

import { getStorageFacilityId } from 'utils';

const LS_SCREEN_ID = 'screenId';
const LS_SUBSCRIPTION_STATUS = 'subscriptionStatus';
const LS_SUBSCRIPTION_LAST_CONNECTION = 'subscriptionLastConnection';

export const initScreenSetup = () => {
  const currentScreenId = localStorage.getItem(LS_SCREEN_ID);
  if (!currentScreenId) {
    localStorage.setItem(LS_SCREEN_ID, cuid().substr(0, 6));
  }
};

export const getScreenId = () => {
  return localStorage.getItem(LS_SCREEN_ID);
};

export const getSubscriptionConnectionInfo = () => {
  return {
    status: sessionStorage.getItem(LS_SUBSCRIPTION_STATUS),
    lastConnection: sessionStorage.getItem(LS_SUBSCRIPTION_LAST_CONNECTION),
    facilityId: getStorageFacilityId(),
  };
};

export const setSubscriptionConnected = (isConnected: boolean) => {
  sessionStorage.setItem(
    LS_SUBSCRIPTION_STATUS,
    isConnected ? 'connected' : 'disconnected',
  );
  if (isConnected) {
    sessionStorage.setItem(
      LS_SUBSCRIPTION_LAST_CONNECTION,
      moment().format('HH:mm'),
    );
  }
};
