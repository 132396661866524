import React from 'react';
import { ToastProvider } from 'react-toast-notifications';

import { ApolloProvider } from '@apollo/client';
import { Router } from 'router';
import { ThemeProvider } from 'styled-components';

import AuthProvider from 'authContext';
import { withErrorBoundary } from 'common/components/ErrorBoundary';
import { GlobalFonts, GlobalStyles, theme } from 'common/theme';
import { SubscriptionsContextProvider } from 'contexts/subscriptionsContext';
import { initApollo } from 'utils/apollo';
import { initFirebaseAnalytics } from 'utils/firebase';
import { initScreenSetup } from 'utils/screenSetup';
import { initSentry } from 'utils/sentry';

// Importing ant design styles with reset less
import 'common/theme/reset.less';

initScreenSetup();
initSentry();
initFirebaseAnalytics();
export const client = initApollo();

const App = () => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ToastProvider>
          <SubscriptionsContextProvider>
            <Router />
            <GlobalFonts />
            <GlobalStyles />
          </SubscriptionsContextProvider>
        </ToastProvider>
      </AuthProvider>
    </ThemeProvider>
  </ApolloProvider>
);

export default withErrorBoundary(App);
