import React from 'react';

import styled, { Color } from 'styled-components';

import Icon, { IconSize } from 'common/components/Icon';
import { IconName } from 'common/components/Icon/types';
import Text from 'common/components/Text';
import { themeColors } from 'common/theme/constants';

import {
  alignItems,
  background,
  border,
  display,
  justifyContent,
  marginR,
  marginT,
  minWidth as minW,
  paddingX,
  paddingY,
  radius,
  color,
} from '../../utils/styleHelpers';
import Badge from '../Badge';
import Loader from '../Loader';

import { Shape } from '../Badge/styles';

export const BUTTON_TEXT_CLASSNAME = 'button__text';

const Button = styled(
  ({
    text,
    textColor,
    backgroundColor,
    badge,
    className,
    id,
    primary,
    secondary,
    iconName,
    fluid,
    ghost,
    minWidth,
    iconSize,
    loading,
    marginLeft,
    fontSize,
    selected,
    ...rest
  }) => (
    <button id={id} className={className} {...rest}>
      {iconName && (
        <Icon
          iconColor={
            primary ? themeColors.secondaryText : themeColors.primaryText
          }
          size={iconSize || IconSize.SMALL}
          name={iconName as IconName}
          type="sharp"
        />
      )}
      <Text
        fontSize={fontSize}
        className={BUTTON_TEXT_CLASSNAME}
        fontWeight="bold"
        color={
          textColor
            ? textColor
            : primary
            ? themeColors.secondaryText
            : themeColors.primaryText
        }
        lineHeight="small"
        marginLeft={marginLeft}
      >
        {text}
      </Text>
      {badge && (
        <Badge
          textColor={themeColors.secondaryText}
          backgroundColor={themeColors.primaryRed}
          text={badge}
          shape={Shape.Round}
        />
      )}
      {loading && (
        <Text marginLeft={10} as="span">
          <Loader />
        </Text>
      )}
    </button>
  ),
)(
  ({
    primary,
    secondary,
    ghost,
    fluid,
    minWidth,
    textColor,
    backgroundColor,
    badge,
    loading,
  }) => `
  ${display('inline-flex')}
  ${alignItems('center')}
  ${justifyContent('center')}
  ${paddingY(10)}
  ${paddingX(10)}
  ${radius('large')}
  ${border(0)}
  outline: none;
  height: 40px;
  width: ${fluid ? '100%' : 'fit-content'};
  cursor: ${loading ? 'not-allowed' : 'pointer'};

  ${minWidth ? minW(minWidth) : ''}

  ${primary ? primaryFn('normal', backgroundColor) : ''}
  ${secondary ? secondaryFn('normal', textColor, backgroundColor) : ''}
  ${ghost ? ghostFn('normal', textColor, backgroundColor) : ''}

  &:hover{
    ${primary ? primaryFn('hover', backgroundColor) : ''}
    ${secondary ? secondaryFn('hover', textColor, backgroundColor) : ''}
    ${ghost ? ghostFn('hover', textColor, backgroundColor) : ''}
  }

  &:selected{
    ${primary ? primaryFn('selected', 'black') : ''}
    ${secondary ? secondaryFn('selected', textColor, backgroundColor) : ''}
    ${ghost ? ghostFn('selected', textColor, backgroundColor) : ''}
  }
  
  &:disabled{
    cursor: not-allowed;
    ${primary ? primaryFn('disabled', backgroundColor) : ''}
    ${secondary ? secondaryFn('disabled', textColor, backgroundColor) : ''}
    ${ghost ? ghostFn('disabled', textColor, backgroundColor) : ''}
  }

  .${BUTTON_TEXT_CLASSNAME} {
    ${display('inline-flex')}
    ${alignItems('center')}
    img {
      ${marginR(3)}
    }
    ${badge && marginR('md')}
  }
  i {
    ${display('inline-flex')}
    ${marginR(4)}
    ${marginT(-2)}
  }
`,
);

const concatColor = (color: string) => color?.slice(6);
type stateProps = 'normal' | 'hover' | 'disabled' | 'selected';

export const primaryFn = (
  state?: stateProps,
  backgroundColor?: keyof Color,
) => {
  let css = '';
  const bgColor = backgroundColor || themeColors.primaryBackgound;
  switch (state) {
    case 'hover':
      css += background([bgColor, 0.9]);
      break;
    case 'disabled':
      css += background([bgColor, 0.5]);
      break;
    case 'selected':
      css += background(themeColors.primaryBackgound);
      break;
    default:
      css += background(bgColor);
  }

  return css;
};

export const secondaryFn = (
  state?: stateProps,
  textColor?: keyof Color,
  backgroundColor?: keyof Color,
) => {
  let css = '';
  const tColor = textColor || themeColors.primaryText;
  const bgColor = backgroundColor || themeColors.secondaryBackground;

  switch (state) {
    case 'hover':
      css += `border: 2px solid ${concatColor(color([tColor, 0.8]))};`;
      css += `p{ ${color([themeColors.primaryText, 0.8])} }`;
      break;
    case 'disabled':
      css += `border: 2px solid ${concatColor(color([tColor, 0.5]))};`;
      css += `p{ ${color([themeColors.primaryText, 0.5])} }`;
      break;
    default:
      css += `border: 2px solid ${concatColor(color([tColor, 0.8]))};`;
  }
  css += background(bgColor);

  return css;
};

export const ghostFn = (
  state?: stateProps,
  textColor?: keyof Color,
  backgroundColor?: keyof Color,
) => {
  let css = '';
  const tColor = textColor || themeColors.primaryText;
  const bgColor = backgroundColor || themeColors.secondaryBackground;
  switch (state) {
    case 'hover':
      css += `p{ ${color([tColor, 0.8])} }`;
      break;
    case 'disabled':
      css += `p{ ${color([tColor, 0.5])} }`;
      css += paddingX(0);
      break;
    default:
      css += `p{ ${color(tColor)} }`;
  }
  css += background(bgColor);
  css += paddingX(0);
  css += `border: none;`;

  return css;
};

export default Button;
