import React from 'react';

import Button from 'common/components/Button';
import { Row, Col } from 'common/components/Layout';
import Loader from 'common/components/Loader';
import Modal from 'common/components/Modal';
import Text from 'common/components/Text';
import { TextAlignProps } from 'common/utils/styleHelpers';

import { DeleteIcon } from './styles';

interface Props {
  icon?: React.ReactNode;
  visible: boolean;
  message: string;
  secondaryMessage?: string;
  onCancel?: () => void;
  onConfirm: () => void;

  textAlign?: TextAlignProps;
  cancelText?: string;
  confirmText?: string;
  loading?: boolean;
}

const ConfirmModal = ({
  visible,
  message,
  secondaryMessage,
  onCancel,
  onConfirm,
  cancelText,
  confirmText,
  loading,
  icon,
  textAlign,
}: Props) => (
  <Modal
    size="alert"
    visible={visible}
    onCloseClick={() => {}}
    closeIcon={false}
  >
    <Row padding="lg" justifyContent="center">
      <Row direction="row">
        <Col flex="1" maxWidth="48px" marginR="sm">
          {icon ?? <DeleteIcon />}
        </Col>
        <Col flex="2" alignItems="center">
          <Text
            fontSize="h4"
            fontWeight="bold"
            textAlign={textAlign ?? 'center'}
          >
            {message}
          </Text>
        </Col>
        <Col marginT="sm">
          <Text fontSize="sm" textAlign={textAlign ?? 'center'}>
            {secondaryMessage}
          </Text>
        </Col>
      </Row>
      <Row marginT="md" justifyContent="center" alignItems="center">
        {onCancel && (
          <Col width="70px" marginR="sm">
            <Button
              disabled={loading}
              fluid
              onClick={onCancel}
              ghost
              text={cancelText ?? 'Cancel'}
            />
          </Col>
        )}
        <Col width="130px">
          <Button
            disabled={loading}
            fluid
            onClick={onConfirm}
            primary
            text={confirmText ?? 'Delete'}
            aria-label="Confirm Delete Item"
            data-cy="admin-Delete-RestauratnConfirmModal-button"
          />
        </Col>
        {loading && (
          <Col marginL="sm">
            <Loader />
          </Col>
        )}
      </Row>
    </Row>
  </Modal>
);

export default ConfirmModal;
