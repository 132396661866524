import React, { useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { AuthContext } from 'authContext';
import { UserRole } from 'codegen/generated/graphql';
import ButtonTabs from 'manager/components/ButtonTabs';
import { Props as RestaurantPageProps, Type } from 'manager/pages/kitchen';
import { Routes } from 'router/routes';
import LogEvent from 'utils/analytics';
import getRoute from 'utils/getRoute';

type Link = {
  label: string;
  value: string;
  disabled: boolean;
  allowedRoles: UserRole[];
  'data-cy'?: string;
};

const KitchenNavigation = ({ type }: RestaurantPageProps) => {
  const { user } = useContext(AuthContext);

  const { kitchenId } = useParams<{ kitchenId: string }>();
  const { push } = useHistory();
  const location = useLocation();

  const updateLinks: Link[] = [
    {
      label: 'Profile',
      value: getRoute(Routes.KITCHEN.path, kitchenId),
      disabled: false,
      allowedRoles: Routes.KITCHEN.allowedRoles,
    },
    {
      label: 'Menu Items',
      value: getRoute(Routes.MENU.path, kitchenId),
      disabled: false,
      allowedRoles: Routes.MENU.allowedRoles,
    },
    {
      label: 'Modifiers',
      value: getRoute(Routes.MODIFIERS.path, kitchenId),
      disabled: false,
      allowedRoles: Routes.MODIFIERS.allowedRoles,
    },
    {
      label: 'Operation Hours',
      value: getRoute(Routes.KITCHEN_OPERATION_HOURS.path, kitchenId),
      disabled: false,
      allowedRoles: Routes.KITCHEN_OPERATION_HOURS.allowedRoles,
    },
    {
      label: 'Active Orders',
      value: getRoute(Routes.RO_ACTIVE_ORDERS.path, kitchenId),
      disabled: false,
      allowedRoles: Routes.RO_ACTIVE_ORDERS.allowedRoles,
    },
  ];

  const addLinks: Link[] = [
    {
      label: 'Profile',
      value: Routes.KITCHEN_ADD.path,
      disabled: false,
      allowedRoles: Routes.KITCHEN_ADD.allowedRoles,
    },
    {
      label: 'Menu',
      value: Routes.KITCHENS.path,
      disabled: true,
      allowedRoles: Routes.KITCHEN.allowedRoles,
    },
  ];

  const links = (
    type === Type.MealPacks ? [] : type === Type.Add ? addLinks : updateLinks
  ).filter((link) =>
    link.allowedRoles.some((allowedRole) => user?.roles.includes(allowedRole)),
  );

  return (
    <ButtonTabs
      options={links}
      data-cy="admin-Restaurnts-Topmenu"
      value={location.pathname}
      onChange={async (value) => {
        push(value);
        await LogEvent('RESTAURANT_MANAGEMENT', {
          message: `navigated to "${value}"`,
          id: kitchenId,
        });
      }}
      checkPartially
    />
  );
};

export default KitchenNavigation;
