import { createGlobalStyle } from 'styled-components';

import {
  margin,
  marginB,
  padding,
  paddingY,
  radius,
  fontWeight,
} from 'common/utils/styleHelpers';

export const GlobalStyles = createGlobalStyle(
  ({ theme }) => `
  /* ANTD THEME ADJUSTMENT */
  // TODO: Find a way how to abstract antd styles
  /* SELECT */
  .ant-select {
    .ant-select-selector.ant-select-selector {
      height: 40px;
      padding: 5px 15px;
      ${radius('large')}
      
      &:hover {
        border-color: ${theme.color.black};
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${theme.color.white};
  }

  .ant-select-dropdown.ant-select-dropdown {
    border-radius: 12px;
  }

  .ant-affix {
    top: 215px !important;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${theme.color.primaryGreen};
  }

  .ant-radio-inner::after {
    background-color: ${theme.color.primaryGreen};
  }

  .ant-radio-checked::after {
    border: 1px solid ${theme.color.primaryGreen};
  }

  .ant-checkbox {
    color: ${theme.color.primaryGreen};
  }
  .ant-checkbox + span {
    padding-right: 24px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${theme.color.primaryGreen};
    border-color: ${theme.color.primaryGreen};
  }

  .ant-checkbox-checked::after {
    border: 1px solid ${theme.color.primaryGreen};
  }

  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${theme.color.primaryGreen};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${theme.color.primaryGreen};
  }

  /* MESSAGE */
  .ant-message-notice-content {
    ${radius('large')}
    padding: 24px;
  }

  /* TABS */
  .ant-tabs-tab {
    font-size: 16px;
    padding-bottom: 5px;
    font-weight: 700;
  }

  .ant-tabs-tab-active {
    font-weight: 700;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-around;
  }

  /* Calendar (manager/components/Calendar/Calendar.tsx) global (absolute) styles for popup */
  .rbc-overlay.rbc-overlay {
    width: 240px;
    ${padding('lg')}
    ${radius('xSmall')}
    font-family: 'benton-sans';
    
    > .rbc-event {
      height: 28px;
      ${fontWeight('bold')}
    
      > .rbc-event-content {
        ${padding('xxs')}
      }
    }
  }

  .rbc-overlay-header.rbc-overlay-header {
    font-family: 'benton-sans';
    ${fontWeight('bold')}
    ${margin(0)}
    ${marginB('sm')}
    padding: 12px 0;
  }

  .rbc-row.rbc-row-resource {
    height: 25px;
    ${paddingY('xxs')}
  }

  #tooltip, .crave-tooltip {
    opacity: 1 !important;
    padding: 16px 14px;
    box-shadow: 0px 2px 10px rgba(97, 97, 97, 0.1);
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
 
  ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }
 
  ::-webkit-scrollbar-thumb {
    background-color: #242424;
    border-radius: 100px;
  }
`,
);
