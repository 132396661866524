import { MenuItemAvailability, OrderMethod } from 'codegen/generated/graphql';
import { WEEK_DAY_INDEXES } from 'common/utils/time';
import { BASE_URL } from 'router/routes';

export enum ProductCategoryLabels {
  SMALL_PLATES = 'Small plates',
  SALADS = 'Salads',
  LARGE_PLATES = 'Large Plates',
  SIDES = 'Sides',
  DRINKS = 'Drinks',
  DESSERTS = 'Desserts',
}

export const KitchenStatus = {
  ACTIVE: { id: 'ACTIVE', label: 'Active' },
  INACTIVE: { id: 'INACTIVE', label: 'Inactive' },
  TEMPORARY_CLOSE: { id: 'TEMPORARY_CLOSE', label: 'Temporarily Close' },
};

export enum ProductPromoTagLabels {
  NEW = 'New',
  FEATURED = 'Featured',
  THREE_COURSES = 'Three Courses',
  SPECIAL = 'Special',
}

export const ItemAvailabilityLabels: {
  [key in MenuItemAvailability]: string;
} = {
  [MenuItemAvailability.Catering]: 'Catering',
  [MenuItemAvailability.Consumer]: 'Consumer App',
  [MenuItemAvailability.Kiosk]: 'Kiosk',
  [MenuItemAvailability.PickupOnly]: 'Pickup',
};

export const ItemAvailabilityTags: {
  [key in MenuItemAvailability]: string;
} = {
  [MenuItemAvailability.Catering]: 'Catering Website',
  [MenuItemAvailability.Consumer]: 'Consumer App',
  [MenuItemAvailability.PickupOnly]: 'Pickup',
  [MenuItemAvailability.Kiosk]: 'Kiosk App',
};

export const MenuScheduleAvailabilityTags: {
  [key in MenuItemAvailability]: string;
} = {
  [MenuItemAvailability.Catering]: 'Catering',
  [MenuItemAvailability.Consumer]: 'Consumer',
  [MenuItemAvailability.PickupOnly]: 'Pickup',
  [MenuItemAvailability.Kiosk]: 'Kiosk',
};

export const OrderMethods: {
  [key in OrderMethod]: string;
} = {
  [OrderMethod.Delivery]: 'Delivery',
  [OrderMethod.PickUp]: 'Pickup',
};

export const SharedPagesUrls = [
  BASE_URL.Analytics,
  BASE_URL.Customers,
  BASE_URL.Promos,
];

export const EVERY_DAY = {
  label: 'Every day',
  value: WEEK_DAY_INDEXES,
};

export const KITCHEN_COLORS = [
  { value: '#779557', label: 'Green' },
  { value: '#579195', label: 'Teal' },
  { value: '#4F75A2', label: 'Blue' },
  { value: '#608FE9', label: 'Azure' }, //default color
  { value: '#836DDD', label: 'Amethyst' },
  { value: '#A664AB', label: 'Violet' },
  { value: '#86808E', label: 'Gray' },
  { value: '#5E5E5E', label: 'Charcoal' },
  { value: '#383838', label: 'Black' },
  { value: '#AC805F', label: 'Brown' },
  { value: '#DB8A68', label: 'Orange' },
  { value: '#E9AD60', label: 'Gold' },
  { value: '#DBB555', label: 'Yellow' },
  { value: '#C9504B', label: 'Red' },
  { value: '#D8CD69', label: 'Lime' },
  { value: '#B9C053', label: 'Pistachio' },
  { value: '#8DAC6C', label: 'Grass' },
  { value: '#5AB6B6', label: 'Sea' },
  { value: '#6570B7', label: 'Blueberry' },
  { value: '#8089C4', label: 'Lavender' },
  { value: '#AB527A', label: 'Burgundy' },
  { value: '#C9537D', label: 'Cherry' },
  { value: '#D48980', label: 'Peach' },
  { value: '#B5A28D', label: 'Tan' },
];
