import { Divider as AntDivider } from 'antd';
import styled from 'styled-components';

import { SpacingType, marginT, marginB } from 'common/utils/styleHelpers';

export const DividerStyle = styled(AntDivider).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['marginTop', 'marginBottom'].includes(prop) && defaultValidatorFn(prop),
})<{
  color?: string;
  height?: number;
  width?: string;
  marginTop?: SpacingType;
  marginBottom?: SpacingType;
}>(
  ({ color, height, width, marginTop, marginBottom }) => `
  border-top: ${height}px solid ${color};
  width: ${width};
  min-width: ${width}; 
  ${marginTop && marginT(marginTop)}
  ${marginBottom && marginB(marginBottom)}`,
);
