import React, { useContext } from 'react';
import { Link, useLocation, Switch, Route } from 'react-router-dom';

import { format } from 'date-fns';

import { AuthContext } from 'authContext';
import { UserRole } from 'codegen/generated/graphql';
import IconButton from 'common/components/IconButton';
import { Row, Col } from 'common/components/Layout';
import { themeColors } from 'common/theme/constants';
import ScreenDebugInfo from 'facility/components/ScreenDebugInfo';
import { useClock } from 'facility/hooks/useClock';
import { Routes as facilityPaths } from 'facility/index';
import { SharedPagesUrls } from 'manager/utils/constants';
import { Routes } from 'router/routes';
import { getRoles } from 'utils';

import Icon, { IconSize } from '../Icon';
import Text from '../Text';

import { Wrapper, Logo, ButtonWrapper, IconWrapper } from './styles';

type NavbarItem = {
  id: string;
  icon: React.ReactElement;
  href: string;
  baseUrl: string;
  title: string;
};

const Navbar: React.FC<{
  workplace?: string;
  withClock?: boolean;
  timeOnly?: boolean;
  userRoles?: UserRole[];
  items?: NavbarItem[];
  navbarHomeItem?: NavbarItem;
}> = ({
  children,
  workplace,
  withClock,
  timeOnly,
  userRoles,
  items,
  navbarHomeItem,
}) => {
  const { logout, selectedFacility } = useContext(AuthContext);
  const { pathname } = useLocation();
  const roles = getRoles(userRoles);
  const facilityView = Object.values(facilityPaths).map(({ path }) => path);
  const managerView = pathname.includes('manager');
  const clock = useClock({ interval: 1 * 1000 });
  const isHomeActive = !SharedPagesUrls.some((page) =>
    location.pathname.includes(page),
  );

  return (
    <Wrapper>
      <Col flex={1} alignItems="center">
        <Switch>
          <Route path={facilityView} exact>
            <Link to={Routes.KDS.path}>
              <Logo
                logoUrl={selectedFacility?.logoUrl ?? '/svg/crave-white.svg'}
              />
            </Link>
          </Route>
          <Route>
            <Link to={Routes.ORDERS.path}>
              <Logo
                logoUrl={selectedFacility?.logoUrl ?? '/svg/crave-white.svg'}
              />
            </Link>
          </Route>
        </Switch>

        <Switch>
          <Route path={facilityView} exact>
            <Row
              flexWrap="nowrap"
              justifyContent="flex-start"
              alignItems="center"
            >
              {workplace && (
                <ButtonWrapper
                  noCursor
                  paddingX={20}
                  marginL={40}
                  height="48px"
                >
                  <Text
                    fontWeight="bold"
                    fontSize="bodyHeader"
                    color={themeColors.secondaryText}
                    alignSelf="center"
                  >
                    {workplace}
                  </Text>
                </ButtonWrapper>
              )}

              {withClock && (
                <>
                  <Col
                    background={[themeColors.secondaryBackground, 0.1]}
                    padding={6}
                    marginR={20}
                    borderRadius="xl"
                    width="max-content"
                    height="48px"
                    marginL={workplace ? 0 : 46}
                  >
                    <Col
                      borderRadius="half"
                      background={themeColors.secondaryBackground}
                      justifyContent="center"
                      width="36px"
                      height="36px"
                      padding={6}
                      marginR={15}
                    >
                      <Icon
                        name="event"
                        size={IconSize.MEDIUM}
                        iconColor={themeColors.primaryText}
                      />
                    </Col>
                    <Text
                      fontSize={timeOnly ? 'h3' : 'bodyHeader'}
                      color={themeColors.secondaryText}
                      alignSelf="center"
                      marginRight={20}
                      fontWeight={timeOnly ? 'bold' : 'normal'}
                    >
                      {timeOnly
                        ? format(clock, 'h:mm:ss a')
                        : format(clock, 'MMM dd, yyyy h:mm:ss a')}
                    </Text>
                  </Col>
                  {children}
                </>
              )}
              <ScreenDebugInfo />
            </Row>
          </Route>
        </Switch>
      </Col>

      {managerView && !roles.restaurantOwner && (
        <Col>
          <Row
            flexWrap="nowrap"
            justifyContent="space-between"
            marginT="auto"
            marginB="auto"
          >
            <Col>
              {items && (
                <Row>
                  {navbarHomeItem && (
                    <Link
                      to={navbarHomeItem?.href}
                      key={navbarHomeItem?.id}
                      title={navbarHomeItem?.title}
                    >
                      <IconWrapper active={isHomeActive}>
                        {React.cloneElement(navbarHomeItem?.icon, {
                          active: isHomeActive,
                        })}
                      </IconWrapper>
                    </Link>
                  )}
                  {items.map(({ id, icon, href, baseUrl, title }) => {
                    const isActive = pathname.includes(baseUrl);

                    return (
                      <Link to={href} key={id} title={title}>
                        <IconWrapper active={isActive}>
                          {React.cloneElement(icon, {
                            active: isActive,
                          })}
                        </IconWrapper>
                      </Link>
                    );
                  })}
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      )}

      <Col>
        <Row alignItems="center" justifyContent="flex-end">
          <IconButton
            onClick={async () => await logout()}
            name="power_settings_new"
            size="large"
            aria-label="Log Out"
            data-cy="admin-logout-button"
          />
        </Row>
      </Col>
    </Wrapper>
  );
};

export default Navbar;
