// TODO: Name more intuitively
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'common/components/Button';
import { Row } from 'common/components/Layout';
import Modal from 'common/components/Modal';
import Text from 'common/components/Text';
import { themeColors } from 'common/theme/constants';

import { SuccessIcon, ErrorIcon, WarningIcon, CancelButton } from './styles';

interface Props {
  visible: boolean;
  message: string;
  secondaryMessage?: string;
  onClose: () => void;
  isError?: boolean;
  isWarning?: boolean;
  leaveOption?: boolean;
  cancelRoute?: any;
  confirm?: boolean;
  doNotConfirmOption?: boolean;
  onDoNotConfirm?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormModal = ({
  visible,
  message,
  secondaryMessage,
  onClose,
  isError,
  isWarning,
  leaveOption,
  cancelRoute,
  confirm,
  doNotConfirmOption,
  onDoNotConfirm = () => {},
}: Props) => {
  const { push } = useHistory();

  const handleCancel = useCallback(() => {
    push(cancelRoute);
  }, [push, cancelRoute]);

  return (
    <Modal
      size="alert"
      visible={visible}
      onCloseClick={() => {}}
      closeIcon={false}
    >
      <Row
        direction="column"
        alignItems="center"
        padding="lg"
        justifyContent="center"
      >
        {isWarning ? (
          <WarningIcon />
        ) : isError ? (
          <ErrorIcon />
        ) : (
          <SuccessIcon />
        )}

        <Text fontSize="h4" fontWeight="bold" textAlign="center">
          {message}
        </Text>

        {secondaryMessage && (
          <Text marginTop={8} color="smokeText" textAlign="center">
            {secondaryMessage}
          </Text>
        )}

        <Row
          marginT="md"
          alignItems="center"
          justifyContent={
            leaveOption || doNotConfirmOption ? 'space-evenly' : 'center'
          }
        >
          {leaveOption && (
            <CancelButton onClick={handleCancel} aria-label="Cancel Button">
              <Text fontSize="h4" fontWeight="bold" textAlign="center">
                Leave
              </Text>
            </CancelButton>
          )}

          {doNotConfirmOption && (
            <CancelButton
              onClick={() => onDoNotConfirm(false)}
              aria-label="Cancel Button"
            >
              <Text fontSize="h4" fontWeight="bold" textAlign="center">
                No
              </Text>
            </CancelButton>
          )}

          <Row width="168px">
            <Button
              primary
              onClick={onClose}
              backgroundColor={themeColors.primaryBackgound}
              aria-label="Item Created Close Button"
              text={confirm ? 'Confirm' : 'Close'}
              type="button"
              data-cy="admin-restaurant-close-modal"
              fluid
            />
          </Row>
        </Row>
      </Row>
    </Modal>
  );
};

export default FormModal;
