import { Routes } from 'router/routes';

export const RESTAURANT_ADMIN_NAV_OPTIONS = [
  {
    label: 'Restaurants',
    value: Routes.KITCHENS.path,
  },
  {
    label: 'Crave Menu',
    value: Routes.MENU_ORDER.path,
  },
  {
    label: 'Multi Restaurant Meal Packs',
    value: Routes.MEAL_PACKS.path,
  },
  {
    label: 'Categories',
    value: Routes.CATEGORIES.path,
  },
];

export const SUPPORTED_FILE_TYPES = [
  'image/svg',
  'image/svg+xml',
  'image/png',
  'image/jpeg',
];
