import React, { FunctionComponent } from 'react';

import { IconName } from './types';

import { IconComponent, StyleProps, SvgIcon } from './styles';

export interface Props extends StyleProps {
  /** name property from names on material-icons */
  name?: IconName;
  url?: string;
  type?: 'outlined' | 'two-tone' | 'round' | 'sharp';
  'data-cy'?: string;
}

/**
 * Icon Component,
 * icons are from material-icons
 * @param {string} name as in material-icons
 * @param {number} fontSize from theme, default 22px
 */

const Icon: FunctionComponent<Props> = ({
  name,
  url,
  size,
  iconColor,
  type,
  onClick,
  'data-cy': dataCypress,
}) =>
  url ? (
    <SvgIcon url={url} size={size} onClick={onClick} />
  ) : (
    <IconComponent
      data-cy={dataCypress}
      size={size}
      iconColor={iconColor}
      className={`material-icons${type ? `-${type}` : ''}`}
      onClick={onClick}
    >
      {name}
    </IconComponent>
  );

export default Icon;
