import React, { useRef, useCallback, useState, useEffect } from 'react';

import Text from 'common/components/Text';
import { themeColors } from 'common/theme/constants';

import Icon from '../Icon';
import { Row, Col } from '../Layout';
import { FILE_SIZE_ERROR, FILE_TYPE_ERROR } from './util';

import * as S from './styles';

interface Props {
  label: string;
  onImageSelect: (file?: File) => void;
  maxSize?: number; //  in KB
  supportedTypes: string[];
  imageUrl?: string;
  subText?: React.ReactNode;
  onTouched?: () => void;
}

const uploadIcon = '/svg/upload.svg';

const UploadImage = ({
  imageUrl,
  maxSize,
  label,
  onImageSelect,
  subText = '',
  onTouched,
  supportedTypes,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [fileName, setFileName] = useState<string>();
  const [fileError, setFileError] = useState<string>();

  useEffect(() => {
    if (imageUrl) {
      const imageFileName = (imageUrl as string)?.split('/').pop();
      setFileName(imageFileName);
    }
    setSelectedImage(imageUrl ?? '');
  }, [imageUrl]);

  const triggerSelect = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const reader = new FileReader();
      const file = event?.target?.files?.[0];
      const size = (file?.size ?? 0) / 1000;
      const type = file?.type ?? '';
      if (!supportedTypes?.includes(type)) {
        console.log(type, supportedTypes);
        setFileError(FILE_TYPE_ERROR);
        setTimeout(() => setFileError(''), 10000);

        return false;
      }
      if (maxSize && size > maxSize) {
        setFileError(FILE_SIZE_ERROR);
        setTimeout(() => setFileError(''), 10000);

        return false;
      }
      reader.addEventListener('load', () => {
        setSelectedImage(reader.result as string);
        setFileName(`${file?.name}`);
      });
      if (file) {
        onTouched?.();
        reader.readAsDataURL(file);
        onImageSelect(file);
      }

      return false;
    },
    [onImageSelect, onTouched, maxSize, supportedTypes],
  );

  const clearSelected = useCallback(() => {
    onTouched?.();
    setSelectedImage('');
    setFileName('');
    onImageSelect();
  }, [onImageSelect, onTouched]);

  return (
    <S.Container direction="column">
      {selectedImage && fileName ? (
        <Row alignItems="center">
          <S.SelectedImage src={selectedImage} />
          <Col marginL="md">
            <Text fontWeight="bold">{fileName}</Text>
          </Col>
          <Col marginL="md">
            <Icon
              name="clear"
              size={24}
              iconColor={themeColors.primaryRed}
              onClick={clearSelected}
            />
          </Col>
        </Row>
      ) : (
        <S.InputWrapper alignItems="center" onClick={triggerSelect}>
          <S.HiddenInput
            ref={inputRef}
            accept="image/jpeg, image/png, image/svg, image/svg+xml"
            name="fileLogo"
            type="file"
            onChange={onInputChange}
          />
          <S.UploadIcon src={uploadIcon} />
          <Col marginL="md">
            <Text>{label}</Text>
          </Col>
        </S.InputWrapper>
      )}
      {subText && (
        <Col marginT="md">
          <Text color="smokeText">{subText}</Text>
        </Col>
      )}
      {Boolean(fileError) && (
        <Col marginT="md">
          <Text color={themeColors.primaryRed}>{fileError}</Text>
        </Col>
      )}
    </S.Container>
  );
};

export default UploadImage;
