/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import fetch from 'cross-fetch';

import { FACILITY_TZ } from 'common/utils/time';

import { auth } from './firebase';
import { logToGCP } from './logging';
import { getScreenId, setSubscriptionConnected } from './screenSetup';
import { WebSocketLink } from './webSocketLink';

import { getStorageFacilityId } from '.';

const getHeaders = async (): Promise<{
  headers: {
    authorization: string;
    platform: string;
    timezone: string;
    screenId?: string;
    facility_id: string;
  };
}> => {
  const token = await auth.currentUser?.getIdToken();

  return {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      platform: 'admin',
      facility_id: getStorageFacilityId() || '',
      timezone: FACILITY_TZ,
      screenId: getScreenId() || undefined,
    },
  };
};

const authLink = setContext(getHeaders);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL || 'http://localhost:4000/graphql',
  fetch,
});

const wsLink = new WebSocketLink({
  url: process.env.REACT_APP_API_WS_URL || 'ws://localhost:4000/graphql',
  connectionParams: getHeaders,
  keepAlive: 30 * 1000,
  lazy: false,
  on: {
    connected: () => {
      const screenId = getScreenId();
      setSubscriptionConnected(true);
      logToGCP(`Client - Subscription connected for screen ${screenId}`, {
        screenId,
      });
    },
    closed: () => {
      const screenId = getScreenId();
      setSubscriptionConnected(false);
      logToGCP(`Client - Subscription disconnected for screen ${screenId}`, {
        screenId,
      });
    },
  },
});

const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const initApollo = () => {
  const client = new ApolloClient({
    link: ApolloLink.from([authLink as any, splitLink]),
    cache: new InMemoryCache({
      dataIdFromObject: (data) => {
        // FIXME: remove dependency of length 10 ⬇️!!!
        // Handles order now timeslot
        if (
          data.__typename === 'Timeslot' &&
          typeof data.id === 'string' &&
          (data.id.length === 10 ||
            (data.id.length === 17 && data.id.endsWith('pickup')))
        ) {
          return undefined;
        }

        if (!data.id || !data.__typename) {
          return undefined;
        }

        return `${data.__typename}:${data.id}`;
      },
      typePolicies: {
        // fix overwriting of menu filters
        MenuFilter: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
        KitchenMenuItem: {
          fields: {
            mealPackItems: {
              merge: false,
            },
          },
        },
      },
    }),
  });

  return client;
};
