import React from 'react';

import Text from 'common/components/Text';
import { themeColors } from 'common/theme/constants';

import { Count } from './styles';

interface Props {
  value: number;
  size: number;
}

const NotificationCount = ({ value, size }: Props) => (
  <Count size={size}>
    <Text
      fontSize="small"
      color={themeColors.secondaryText}
      fontWeight="bold"
      lineHeight="initial"
    >
      {value}
    </Text>
  </Count>
);
export default NotificationCount;
