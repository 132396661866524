import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import { ErrorContextProvider } from './hooks/errorContext';
import { UserRole } from 'codegen/generated/graphql';
import PrivateRoute from 'router/PrivateRoute';
import { FacilityManagementAccessors } from 'utils';

const Home = lazy(() => import('./pages/home'));
const Kitchen = lazy(() => import('./pages/kitchen'));
const Station = lazy(() => import('./pages/station'));
const FutureKitchenTickets = lazy(
  () => import('./pages/future-kitchen-tickets'),
);
const Expo = lazy(() => import('./pages/expo'));
const OrderAssignment = lazy(() => import('./pages/order-assignment'));
const Packager = lazy(() => import('./pages/packager'));
const Runner = lazy(() => import('./pages/runner'));
const Pickup = lazy(() => import('./pages/pickup'));
const TableAssignment = lazy(() => import('./pages/table-assignment'));
const FOH = lazy(() => import('./pages/FOH'));
const ExpoRecent = lazy(() => import('./pages/expo-recent'));
const PickupSearch = lazy(() => import('./pages/pickup-search'));
const KioskOrderStatus = lazy(() => import('./pages/kiosk-order-status'));

const BasicViewsAccessors = [
  UserRole.FacilityAdmin,
  UserRole.CorporateAdmin,
  UserRole.RestaurantOwner,
  UserRole.RestaurantEmployee,
  UserRole.CraveEmployee,
];

const CraveEmployeeAccessors = [
  UserRole.FacilityAdmin,
  UserRole.CorporateAdmin,
  UserRole.CraveEmployee,
];

export const Routes = {
  HOME: {
    path: '/facility',
    allowedRoles: BasicViewsAccessors,
  },
  KITCHEN: {
    path: '/facility/kitchen/:id',
    allowedRoles: BasicViewsAccessors,
  },
  KITCHEN_FUTURE_ORDERS: {
    path: '/facility/kitchen/:id/future-orders',
    allowedRoles: BasicViewsAccessors,
  },
  EXPO: {
    path: '/facility/expo',
    allowedRoles: CraveEmployeeAccessors,
  },
  EXPO_RECENT: {
    path: '/facility/expo/recent-tickets',
    allowedRoles: CraveEmployeeAccessors,
  },
  ORDER_ASSIGNMENT: {
    path: '/facility/order-assignment',
    allowedRoles: CraveEmployeeAccessors,
  },
  PACKAGER: {
    path: '/facility/packager/:id',
    allowedRoles: CraveEmployeeAccessors,
  },
  RUNNER: {
    path: '/facility/runner',
    allowedRoles: CraveEmployeeAccessors,
  },
  PICKUP: {
    path: '/facility/pickup',
    allowedRoles: CraveEmployeeAccessors,
  },
  PICKUP_SEARCH: {
    path: '/facility/pickup/search',
    allowedRoles: CraveEmployeeAccessors,
  },
  TABLE_ASSIGNMENT: {
    path: '/facility/table-assignment',
    allowedRoles: CraveEmployeeAccessors,
  },
  STATION: {
    path: '/facility/station/:id',
    allowedRoles: BasicViewsAccessors,
  },
  FOH: {
    path: '/facility/foh',
    allowedRoles: CraveEmployeeAccessors,
  },
  ORDER_STATUS: {
    path: '/facility/kiosk-order-status',
    allowedRoles: CraveEmployeeAccessors,
  },
  TEST_SUBSCRIPTIONS: {
    path: '/facility/test-subscriptions',
    allowedRoles: FacilityManagementAccessors,
  },
};

export default () => {
  return (
    <ErrorContextProvider>
      <Switch>
        <PrivateRoute exact component={Home} {...Routes.HOME} />
        <PrivateRoute exact component={ExpoRecent} {...Routes.EXPO_RECENT} />
        <PrivateRoute
          exact
          component={PickupSearch}
          {...Routes.PICKUP_SEARCH}
        />
        <PrivateRoute
          exact
          component={FutureKitchenTickets}
          {...Routes.KITCHEN_FUTURE_ORDERS}
        />

        <PrivateRoute exact component={Kitchen} {...Routes.KITCHEN} />
        <PrivateRoute exact component={Expo} {...Routes.EXPO} />
        <PrivateRoute
          exact
          component={OrderAssignment}
          {...Routes.ORDER_ASSIGNMENT}
        />
        <PrivateRoute exact component={Packager} {...Routes.PACKAGER} />
        <PrivateRoute exact component={Runner} {...Routes.RUNNER} />
        <PrivateRoute exact component={Pickup} {...Routes.PICKUP} />
        <PrivateRoute
          exact
          component={TableAssignment}
          {...Routes.TABLE_ASSIGNMENT}
        />
        <PrivateRoute exact component={Station} {...Routes.STATION} />
        <PrivateRoute exact component={FOH} {...Routes.FOH} />
        <PrivateRoute component={KioskOrderStatus} {...Routes.ORDER_STATUS} />
      </Switch>
    </ErrorContextProvider>
  );
};
