import React, { useContext } from 'react';
import { Detector } from 'react-detect-offline';

import styled from 'styled-components';

import { Row, Grid } from 'common/components/Layout';
import Text from 'common/components/Text';
import { themeColors } from 'common/theme/constants';
import { background } from 'common/utils/styleHelpers';
import { SubscriptionsContext } from 'contexts/subscriptionsContext';
import { getScreenId, getSubscriptionConnectionInfo } from 'utils/screenSetup';

const Dot = styled.span(
  ({ online }: { online: boolean }) => `
    display: inline-block;
    ${background(online ? 'primaryGreen' : 'primaryRed')}
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
`,
);

const ScreenDebugInfo = () => {
  const { isSubscriptionsOn } = useContext(SubscriptionsContext);

  const { status, lastConnection, facilityId } =
    getSubscriptionConnectionInfo();

  return (
    <Grid width="unset" gridColumns="2fr 2fr" gridGap="sm" alignItems="center">
      <Row>
        <Text
          fontSize="md"
          color={themeColors.secondaryText}
          alignSelf="center"
        >
          Screen ID: {getScreenId()}
        </Text>
      </Row>
      <Row>
        <Text
          fontSize="md"
          color={themeColors.secondaryText}
          alignSelf="center"
        >
          Network status:{' '}
          <Detector
            render={({ online }: { online: boolean }) => (
              <Dot online={online} />
            )}
          />
        </Text>
      </Row>
      <Row>
        <Text
          fontSize="md"
          color={themeColors.secondaryText}
          alignSelf="center"
        >
          Last connection: {lastConnection}
        </Text>
      </Row>
      <Row>
        <Text
          fontSize="md"
          color={themeColors.secondaryText}
          alignSelf="center"
        >
          Subscriptions status: <Dot online={status === 'connected'} />
        </Text>
      </Row>
      <Row>
        <Text
          fontSize="md"
          color={themeColors.secondaryText}
          alignSelf="center"
        >
          Facility: {facilityId}
        </Text>
      </Row>
      <Row>
        <Text
          fontSize="md"
          color={themeColors.secondaryText}
          alignSelf="center"
        >
          Running on: {isSubscriptionsOn ? 'subscriptions' : 'polling'}
        </Text>
      </Row>
    </Grid>
  );
};

export default ScreenDebugInfo;
