import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { KitchenMenuItem } from 'codegen/generated/graphql';

import { Props } from '../types';
import MenuItem from './MenuItem';

import { SortableWrapper } from '../styles';

type SortableListProps = Pick<
  Props,
  | 'items'
  | 'readOnly'
  | 'showCategory'
  | 'isRestaurantOwner'
  | 'isCustomerSupport'
>;
type SortableItemProps = Pick<
  Props,
  'readOnly' | 'showCategory' | 'isRestaurantOwner' | 'isCustomerSupport'
> & {
  item: KitchenMenuItem;
};

const SortableList = SortableContainer(
  ({ items, ...rest }: SortableListProps) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            item={value}
            {...rest}
          />
        ))}
      </ul>
    );
  },
);

const SortableItem = SortableElement(({ item, ...rest }: SortableItemProps) => (
  <li>
    <MenuItem key={item.id} item={item} {...rest} />
  </li>
));

const SortableMenuList = ({ items, onChange, ...rest }: Props) => {
  return (
    <SortableWrapper aria-label="crave menu list">
      <SortableList
        items={items}
        useDragHandle
        onSortEnd={({ oldIndex, newIndex }) =>
          onChange({ oldIndex, newIndex, items })
        }
        {...rest}
      />
    </SortableWrapper>
  );
};

export default SortableMenuList;
