import { UserRole } from 'codegen/generated/graphql';

export const isRouteAllowed = (allowedRoles: UserRole[], roles: UserRole[]) =>
  allowedRoles.some((r) => roles.includes(r));

const ROLE_PRIVILAGE = [
  UserRole.KioskCustomer,
  UserRole.Customer,
  UserRole.CateringCustomer,
  UserRole.CraveEmployee,
  UserRole.RestaurantEmployee,
  UserRole.RestaurantOwner,
  UserRole.FacilityAdmin,
  UserRole.CorporateAdmin,
];

export const getMostPrivilegedRole = (roles: UserRole[]) => {
  return roles.reduce(
    (acc, curr) =>
      ROLE_PRIVILAGE.indexOf(curr) > ROLE_PRIVILAGE.indexOf(acc) ? curr : acc,
    UserRole.KioskCustomer,
  );
};

// User should never have ADMIN and RESTAURANT_OWNER role at the same time
export const isUserRestaurantOwner = (roles?: UserRole[]) =>
  roles && roles.includes(UserRole.RestaurantOwner);

export const isUserCorporateAdmin = (roles?: UserRole[]) =>
  roles && roles.includes(UserRole.CorporateAdmin);

export const isUserFacilityAdmin = (roles?: UserRole[]) =>
  roles && roles.includes(UserRole.FacilityAdmin);

export const isUserAdmin = (roles?: UserRole[]) =>
  roles &&
  (roles.includes(UserRole.FacilityAdmin) ||
    roles.includes(UserRole.CorporateAdmin));

export const isUserRestaurantEmployee = (roles?: UserRole[]) =>
  roles && roles.includes(UserRole.RestaurantEmployee);

export const isUserCraveEmployee = (roles?: UserRole[]) =>
  roles && roles.includes(UserRole.CraveEmployee);

export const isUserCustomerSupport = (roles?: UserRole[]) =>
  roles && roles.includes(UserRole.CustomerSupport);

export const getRoles = (roles?: UserRole[]) => ({
  admin: isUserAdmin(roles),
  restaurantOwner: isUserRestaurantOwner(roles),
  restaurantEmployee: isUserRestaurantEmployee(roles),
  craveEmployee: isUserCraveEmployee(roles),
  customerSupport: isUserCustomerSupport(roles),
});

export const BasicViewsAccessors = [
  UserRole.RestaurantOwner,
  UserRole.CustomerSupport,
  UserRole.FacilityAdmin,
  UserRole.CorporateAdmin,
];

export const FacilityEmployeeAccessors = [
  UserRole.FacilityAdmin,
  UserRole.CorporateAdmin,
  UserRole.CustomerSupport,
];

export const RestaurantManagementAccessors = [
  UserRole.RestaurantOwner,
  UserRole.FacilityAdmin,
  UserRole.CorporateAdmin,
];

export const FacilityManagementAccessors = [
  UserRole.FacilityAdmin,
  UserRole.CorporateAdmin,
];
