import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AuthContext } from 'authContext';
import { UserRole } from 'codegen/generated/graphql';
import { AuthenticatedUserRedirect } from 'router/AuthenticatedUserRedirect';
import { Routes } from 'router/routes';
import { isRouteAllowed } from 'utils';

interface Props extends RouteProps {
  allowedRoles?: UserRole[];
}

export default ({ allowedRoles, ...rest }: Props) => {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <Redirect to={Routes.LOGIN.path} />;
  }

  if (allowedRoles && !isRouteAllowed(allowedRoles, user?.roles || [])) {
    return <AuthenticatedUserRedirect user={user} />;
  }

  return <Route {...rest} />;
};
