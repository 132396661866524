export const isDevelopment = () =>
  process.env.REACT_APP_CUSTOM_ENV === 'development';
export const isStaging = () => process.env.REACT_APP_CUSTOM_ENV === 'staging';
export const isDemo = () => process.env.REACT_APP_CUSTOM_ENV === 'demo';
export const isRelease = () => process.env.REACT_APP_CUSTOM_ENV === 'demo';
export const isProduction = () =>
  ['production', 'demo', 'release'].includes(
    process.env.REACT_APP_CUSTOM_ENV ?? '',
  );

// default poll interval is 5 seconds
export const DEFAULT_POLL_INTERVAL =
  Number(process.env.REACT_APP_KDS_POLL_INTERVAL) || 5000;

// how many recently done items do we display
export const RECENTLY_DONE_LIMIT = 5;

export const SUBSCRIPTIONS_FLAG_ON =
  process.env.REACT_APP_SUBSCRIPTIONS_FLAG_ON === 'true';

export const CHOWLY_FLAG_ON = process.env.REACT_APP_ENABLE_CHOWLY === 'true';

export * from './roles';

export const getHashLink = (title: string) => title.split(' ').join('-');

// Getting value from session storage and local storage - session storage is preffered
export const getStorageFacilityId = () =>
  sessionStorage.getItem('facilityId') || localStorage.getItem('facilityId');

// we check if both storages have facilityId set, to set one if needed
export const areBothStorageFacilityIdsSet =
  sessionStorage.getItem('facilityId') && localStorage.getItem('facilityId');

// Setting new facility id to local storage and as well session storage
// Local storage is permanently in the storage even when user close the web app or opens new tab
// Session storage is unique for every app running - different tabs = different session storages
export const setNewStorageFacilityId = (facilityId: string) => {
  sessionStorage.setItem('facilityId', facilityId);
  localStorage.setItem('facilityId', facilityId);

  return true;
};
