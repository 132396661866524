import React from 'react';

import { Property as CsstypeProperty } from 'csstype';
import { Shadows, Color } from 'styled-components';

import { Row, Col } from 'common/components/Layout';
import Loader from 'common/components/Loader';
import Text from 'common/components/Text';

import { Wrapper, Content, LoadingWrapper } from './styles';

interface Props {
  title?: string;
  shadow?: keyof Shadows;
  headerBg?: keyof Color;
  headerBorder?: boolean;
  radius?: 'all' | 'top' | 'bottom';
  height?: string;
  display?: CsstypeProperty.Display;
  subheader?: JSX.Element;
  footer?: JSX.Element;
  loading?: boolean;
}

const Panel: React.FC<Props> = ({
  children,
  title,
  shadow,
  headerBg,
  headerBorder = false,
  height,
  display = 'flex',
  radius = 'top',
  subheader,
  footer,
  loading,
}) => {
  return (
    <Wrapper
      direction="column"
      height={height}
      display={display}
      shadow={shadow}
      {...(radius === 'all'
        ? {
            radiusBL: 'small',
            radiusBR: 'small',
            radiusTL: 'small',
            radiusTR: 'small',
          }
        : {})}
      {...(radius === 'top' ? { radiusTL: 'small', radiusTR: 'small' } : {})}
      {...(radius === 'bottom' ? { radiusBR: 'small', radiusBL: 'small' } : {})}
    >
      {loading && (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      )}
      {title && (
        <Col>
          <Row
            background={headerBg ?? 'beige'}
            padding="sm"
            {...(headerBorder ? { borderBottom: 1 } : {})}
          >
            <Text fontWeight="thin">{title}</Text>
          </Row>
        </Col>
      )}
      <Col>{subheader}</Col>
      <Content>
        <Row direction="column">{children}</Row>
      </Content>
      {footer}
    </Wrapper>
  );
};

export default Panel;
