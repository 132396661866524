import styled from 'styled-components';

import { Row } from 'common/components/Layout';
import {
  zIndex,
  background,
  position,
  modalSize,
  radius,
  alignItems,
  justifyContent,
  display,
  ModalSizeType,
} from 'common/utils/styleHelpers';

interface ModalProps {
  size: ModalSizeType;
}
export const Modal = styled.div<ModalProps>(
  ({ size }) => `
  ${position('fixed')}
  ${zIndex('modal')}
  ${background('white')}
  ${modalSize(size)}
  ${radius('normal')}
  top: 15%;
  overflow: hidden;
  word-break: break-word;
  `,
);

export const Header = styled(Row)(
  () => `
  ${position('absolute')}
  top: -48px;
  left: 0px;
  right: 0px;
`,
);

export const Wrapper = styled.div`
  ${position('relative')}
`;

export const CloseIcon = styled.button(
  () => `
  ${radius('half')}
  ${display('inline-flex')}
  ${alignItems('center')}
  ${justifyContent('center')}
  outline: none;
  border: none;
  cursor: pointer;
  background: white;
  width: 32px;
  height: 32px;
`,
);

export const Shadow = styled.div(
  () => `
  ${position('fixed')}
  ${zIndex('shadow')}
  background: rgba(0,0,0,.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`,
);
