import styled from 'styled-components';

import { theme } from 'common/theme';
import {
  fontSize,
  color,
  ColorProps,
  margin,
  alignSelf,
  SpacingType,
} from 'common/utils/styleHelpers';

import { Props } from './Icon';

export enum IconSize {
  SMALL = 'md',
  NORMAL = 'lg',
  MEDIUM = 'xl',
  LARGE = 'xxl',
}

const svgSize = (size: IconSize | number) =>
  `${typeof size === 'number' ? `${size}px` : theme.fontSize[size]}`;

export interface StyleProps {
  size: IconSize | number;
  iconColor?: ColorProps;
  backgroundColor?: ColorProps;
  margin?: SpacingType;
  onClick?: () => void;
}

const IconComponent = styled.i<Omit<Props, 'name'>>(
  ({ size, iconColor, onClick }) => `
  ${fontSize(size)}
  ${iconColor ? color(iconColor) : ''}
  ${onClick ? 'cursor: pointer;' : ''}
  user-select: none;
`,
);

const SvgIcon = styled.div<Omit<Props, 'name' | 'iconColor'>>(
  ({ url, onClick, size }) => `
  ${margin(6)}
  ${alignSelf('center')}
  width: ${svgSize(size)};
  height: ${svgSize(size)};
  ${onClick ? 'cursor: pointer;' : ''}
  user-select: none;
  background: url("${url}") no-repeat;
  background-size: contain;
`,
);

export { IconComponent, SvgIcon };
