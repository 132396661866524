import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthContext, Me } from 'authContext';
import { UserRole } from 'codegen/generated/graphql';
import { Col, Row } from 'common/components/Layout';
import Loader from 'common/components/Loader';
import Navbar from 'common/components/Navbar';
import Text from 'common/components/Text';
import { themeColors } from 'common/theme/constants';
import NotificationCount from 'manager/components/NotificationCount';
import Sidebar, { SidebarItem } from 'manager/components/Sidebar';
import {
  Analytic,
  Home,
  Kitchen,
  Orders,
  Customers,
  Promos,
  KDS,
  Content,
  MenuPlannerIcon,
  Settings,
} from 'manager/components/Sidebar/SidebarIcon';
import TopBanner, {
  Props as TopBannerProps,
} from 'manager/components/TopBanner';
import { SharedPagesUrls } from 'manager/utils/constants';
import { Routes, BASE_URL } from 'router/routes';
import {
  isUserAdmin,
  FacilityManagementAccessors,
  BasicViewsAccessors,
  FacilityEmployeeAccessors,
} from 'utils';

import {
  Container,
  ContentPanelIndex,
  SideBarPanel,
  LeftActionDivider,
} from './styles';

const navbarHomeItem = {
  id: 'home',
  icon: <Home color="white" />,
  href: Routes.ORDERS.path,
  baseUrl: BASE_URL.Orders,
  title: 'Home',
  roles: BasicViewsAccessors,
};

const navbarMenuItems = [
  {
    id: 'reports',
    icon: <Analytic color={themeColors.white} />,
    href: Routes.ANALYTICS.path,
    baseUrl: BASE_URL.Analytics,
    title: 'Reports',
    roles: FacilityManagementAccessors,
  },
  {
    id: 'customers',
    icon: <Customers color={themeColors.white} />,
    href: Routes.CUSTOMERS.path,
    baseUrl: BASE_URL.Customers,
    title: 'Customers',
    roles: FacilityEmployeeAccessors,
  },
  {
    id: 'promos',
    icon: <Promos color={themeColors.white} />,
    href: Routes.PROMOS.path,
    baseUrl: BASE_URL.Promos,
    title: 'Promos',
    roles: FacilityEmployeeAccessors,
  },
];

const getMenuPlannerSidebarItems = (user: Me) => {
  const userRoles = user?.roles ?? [];

  return [
    {
      id: 'menu-planner',
      icon: <MenuPlannerIcon />,
      href: isUserAdmin(userRoles)
        ? Routes.MENU_PLANNER_ALL.path
        : `${BASE_URL.MenuPlanner}/${user?.kitchens[0]?.id}`,
      baseUrl: BASE_URL.MenuPlanner,
      title: 'Menu Planner',
      roles: isUserAdmin(userRoles)
        ? Routes.MENU_PLANNER_ALL.allowedRoles
        : Routes.MENU_PLANNER_SINGLE_KITCHEN.allowedRoles,
    },
  ];
};

const getSidebarMenuItems = (user: Me) => [
  {
    id: 'orders',
    icon: <Orders />,
    href: Routes.ORDERS.path,
    baseUrl: BASE_URL.Orders,
    title: 'Orders',
    roles: FacilityEmployeeAccessors,
  },
  ...getMenuPlannerSidebarItems(user),
  {
    id: 'restaurantAdministration',
    icon: <Kitchen />,
    href: Routes.KITCHENS.path,
    baseUrl: BASE_URL.Kitchen,
    title: 'Restaurant Administration',
    roles: BasicViewsAccessors,
  },
  {
    id: 'facilitySettings',
    icon: <Settings />,
    href: Routes.FACILITY_PROFILE.path,
    baseUrl: BASE_URL.FacilitySettings,
    title: 'Facility Settings',
    roles: FacilityManagementAccessors,
  },
  {
    id: 'content',
    icon: <Content />,
    href: Routes.CONTENT_MARKETING.path,
    baseUrl: BASE_URL.Content,
    title: 'Content Management',
    roles: FacilityManagementAccessors,
  },
  {
    id: 'analytics',
    icon: <Analytic />,
    href: Routes.ANALYTICS.path,
    baseUrl: BASE_URL.Analytics,
    title: 'Analytics',
    roles: [UserRole.RestaurantOwner],
  },
  {
    id: 'kds',
    icon: <KDS />,
    href: Routes.KDS.path,
    baseUrl: Routes.KDS.path,
    title: 'KDS',
    roles: BasicViewsAccessors,
  },
];

const getSidebarItems = (user: Me): SidebarItem[] => {
  const userRoles = user?.roles ?? [];
  const sidebarMenuItems = getSidebarMenuItems(user);

  return sidebarMenuItems.filter((item) =>
    item.roles.some((role) => userRoles.includes(role)),
  );
};

const getNavbarItems = (roles?: UserRole[]): SidebarItem[] =>
  navbarMenuItems.filter((item) =>
    item.roles.some((role) => roles?.includes(role)),
  );

const topBannerData = {
  image: '/images/top-banner.png',
  tags: ['Dinner', 'Late-Night'],
  name: 'Le Charmant',
  numbers: ['6', '82.5%', '88%'],
  infos: ['Employees', 'Productivity rate', 'Satisfaction rate'],
};

interface Props {
  title?: string;
  notificationCount?: number;
  loading?: boolean;
  topBanner?: TopBannerProps;
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  titleActions?: React.ReactNode;
  displayBottomPadding?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  topBanner,
  title,
  notificationCount,
  loading,
  titleActions,
  leftActions,
  rightActions,
  displayBottomPadding = true,
}) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const { pathname } = useLocation();
  const isMenuPlannerView = pathname.includes(`${BASE_URL.MenuPlanner}/`);
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    const isCommonPage = SharedPagesUrls.some((page) =>
      location.pathname.includes(page),
    );
    setShowSidebar(!(isCommonPage && isUserAdmin(user?.roles)));
  }, [user, location]);

  // TODO: Can it happen that user will be undefined, if yes do we want to handle this exception?
  return (
    <Container>
      {showSidebar && (
        <SideBarPanel>
          <Sidebar items={getSidebarItems(user)} />
        </SideBarPanel>
      )}

      <ContentPanelIndex>
        <Row>
          <Navbar
            navbarHomeItem={navbarHomeItem}
            items={getNavbarItems(user?.roles)}
            userRoles={user?.roles}
          />

          {topBanner && (
            <Row>
              <TopBanner {...topBannerData} />
            </Row>
          )}

          <Row
            marginL={showSidebar ? 100 : 'auto'}
            paddingB={!displayBottomPadding ? 0 : 52}
            paddingX={52}
            paddingT={!topBanner ? 40 : undefined}
          >
            <Row
              justifyContent="space-between"
              alignItems="center"
              paddingB="md"
            >
              <Col display="flex" alignItems="center">
                {titleActions && (
                  <Col marginB={8} marginR="sm">
                    {titleActions}
                  </Col>
                )}

                {title && (
                  <Col marginR="sm">
                    <Text
                      fontSize="h3"
                      fontWeight="bold"
                      aria-label="page title"
                    >
                      {title}
                    </Text>
                  </Col>
                )}

                {loading ? (
                  <Col marginR="sm">
                    <Loader />
                  </Col>
                ) : (
                  typeof notificationCount !== 'undefined' && (
                    <NotificationCount
                      size={30}
                      value={notificationCount ?? 0}
                    />
                  )
                )}

                {isMenuPlannerView && leftActions ? (
                  <Col>
                    <LeftActionDivider>{leftActions}</LeftActionDivider>
                  </Col>
                ) : (
                  <Col>{leftActions}</Col>
                )}
              </Col>
              {rightActions}
            </Row>
            {children}
          </Row>
        </Row>
      </ContentPanelIndex>
    </Container>
  );
};

export default Layout;
