import React from 'react';

import { Row, Col } from 'common/components/Layout';
import Text from 'common/components/Text';

interface Props {
  inventoryCountQL?: number;
  inventoryPreSale: number;
}

const PhysicalInventory = ({ inventoryPreSale }: Props) => {
  return (
    <Row alignItems="center">
      <Col alignItems="center">
        <Text marginRight="xs" color="steelWool">
          Pre-sale:
        </Text>
        <Text marginRight="xs">{inventoryPreSale}</Text>
        {/* 
        Based on the current implementation of inventoryCount this information doesn't make sense"
        <Text marginRight="xs" color="steelWool">{`| Available:`}</Text>
        <Text color="dark">{inventoryCountQL}</Text> */}
      </Col>
    </Row>
  );
};

export default PhysicalInventory;
