import React from 'react';

import { Props as SwitchProps } from './types';

import { SwitchWrapper, SwitchButton } from './styles';

interface Props extends SwitchProps {
  onClick: (currentState: boolean) => void;
  disabled?: boolean;
}

const Switch: React.FC<Props> = ({ onClick, isActive, disabled, ...rest }) => (
  <SwitchWrapper
    onClick={() => {
      if (!disabled) onClick(!!isActive);
    }}
    isActive={isActive}
    disabled={disabled}
    {...rest}
  >
    <SwitchButton />
  </SwitchWrapper>
);

export default Switch;
