import { logEvent } from './firebase';

type Categories =
  | 'ORDERS_MONITORING'
  | 'ORDERS_MONITORING_SIDEBAR'
  | 'ORDERS_MONITORING_DELIVERY_INPUT'
  | 'ORDERS_MONITORING_BOXLIST'
  | 'ORDERS_MONITORING_STATUS_DROPDOWN'
  | 'RESTAURANT_MANAGEMENT'
  | 'RESTAURANT_FORM'
  | 'MENU_MANAGEMENT'
  | 'TIMESLOTS'
  | 'ANALYTICS_TABS'
  | 'CONTENT_MANAGEMENT_TABS'
  | 'ANALYTICS_DOWNLOAD_CSV';

const LogEvent = async (
  category: Categories,
  params: {
    message: string;
    id?: string;
  },
) => {
  return logEvent(category, params, {
    global: true,
  });
};

export default LogEvent;
