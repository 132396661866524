import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import ButtonBack from 'common/components/ButtonBack';
import { Row, Col } from 'common/components/Layout';
import Text from 'common/components/Text';
import KitchenNavigation from 'manager/components/KitchenNavigation';
import { Props as RestaurantPageProps, Type } from 'manager/pages/kitchen';
import { Routes } from 'router/routes';
import LogEvent from 'utils/analytics';
import getRoute from 'utils/getRoute';

type LayoutProps = {
  name: string;
  kitchenId?: string;
  isPartnerKitchen?: boolean;
  showNavButtons?: boolean;
  backButtonRoute?: string;
} & RestaurantPageProps;

const Layout: React.FC<LayoutProps> = ({
  children,
  name,
  type,
  kitchenId,
  isPartnerKitchen,
  showNavButtons = true,
  backButtonRoute,
}) => {
  const { push } = useHistory();

  // TODO: Refactor, it starting to be messy - use goBack() from useHistory instead?
  const routeBack = useMemo(() => {
    switch (true) {
      case type === Type.MealPacks && !isPartnerKitchen:
        return Routes.MEAL_PACKS.path;
      case Boolean(kitchenId):
        return getRoute(Routes.MENU.path, kitchenId as string);
      case type === Type.OrderMethodOverrideUpsert:
        return Routes.ORDER_METHODS_OVERRIDES.path;
      case type === Type.MenuPlanner:
        return Routes.MENU_PLANNER_ALL.path;
      case type === Type.SpecialMenuPlanner:
        return Routes.MENU_PLANNER_SPECIALS.path;
      default:
        return Routes.KITCHENS.path;
    }
  }, [type, isPartnerKitchen, kitchenId]);

  return (
    <>
      <Row alignItems="center" marginB={32}>
        <Col marginR={24}>
          <ButtonBack
            onClick={async () => {
              push(backButtonRoute ?? routeBack);
              await LogEvent('RESTAURANT_MANAGEMENT', {
                message: `clicked "back" button`,
              });
            }}
          />
        </Col>

        <Col marginR={32}>
          <Text fontSize="h3" fontWeight="bold">
            {name}
          </Text>
        </Col>

        <Col>{showNavButtons && <KitchenNavigation type={type} />}</Col>
      </Row>
      <Row>{children}</Row>
    </>
  );
};

export default Layout;
