import React, { useContext } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import { AuthContext, ContextFacility } from 'authContext';
import Text from 'common/components/Text';
import ActionMenu from 'manager/components/ActionMenu';
import { Routes, NON_REDIRECT_URLS } from 'router/routes';
import { setNewStorageFacilityId } from 'utils';

import { HelpCenterIcon } from './SidebarIcon';

import {
  Wrapper,
  IconWrapper,
  TextWrapper,
  ToggleWrapper,
  SidebarBottomWrapper,
} from './styles';

export type SidebarItem = {
  id: string;
  icon: React.ReactElement;
  href: string;
  baseUrl: string;
  title: string;
};

interface Props {
  items: SidebarItem[];
}

const shouldRedirect = (items: SidebarItem[], pathname: string) =>
  !items.some((item) => item.href === pathname) &&
  !NON_REDIRECT_URLS.includes(pathname);

const Sidebar = ({ items }: Props) => {
  const { push } = useHistory();
  const { resetStore } = useApolloClient();
  const location = useLocation();
  const { user, selectedFacility, setFacilityById } = useContext(AuthContext);

  const facilityName = selectedFacility?.name;
  const formatFacilities = (facilities: ContextFacility[]) =>
    facilities.map((facility) => ({
      label: facility.name,
      id: facility.id,
      onClick: async () => {
        if (selectedFacility && selectedFacility.id !== facility.id) {
          setFacilityById(facility.id);
          setNewStorageFacilityId(facility.id);
          await resetStore();
          if (shouldRedirect(items, location.pathname))
            push(Routes.ORDERS.path);
        }
      },
    }));
  const facilities = user?.facilities ? formatFacilities(user.facilities) : [];

  return (
    <Wrapper>
      <ToggleWrapper>
        <ActionMenu
          locationToggle
          leftAlign
          actions={facilities}
          selectedFacilityId={selectedFacility?.id}
        />
        <TextWrapper>
          <Text fontSize="xs" fontWeight="bold" marginTop={10}>
            {facilityName}
          </Text>
        </TextWrapper>
      </ToggleWrapper>

      <Wrapper>
        {items.map(({ id, icon, href, baseUrl, title }) => {
          const isActive = location.pathname.includes(baseUrl);

          return (
            <Link to={href} key={id} title={title}>
              <IconWrapper active={isActive}>
                {React.cloneElement(icon, {
                  active: isActive,
                })}
              </IconWrapper>
            </Link>
          );
        })}
        <SidebarBottomWrapper>
          <Link
            to={{ pathname: 'https://cravedelivery.zendesk.com/hc/en-us' }}
            target="_blank"
            title="Help Center"
          >
            <IconWrapper>
              <HelpCenterIcon />
            </IconWrapper>
          </Link>
        </SidebarBottomWrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default Sidebar;
