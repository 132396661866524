import styled, { Color, Spacing } from 'styled-components';

import Button from 'common/components/Button';
import { Row } from 'common/components/Layout';
import {
  background,
  radius,
  fontSize,
  textAlign,
  marginR,
  alignSelf,
  marginB,
} from 'common/utils/styleHelpers';

export const Wrapper = styled(Row)<{
  bgColor?: keyof Color;
}>(
  ({ bgColor }) => `
  ${radius('xxxxl')}
  ${bgColor ? background(bgColor) : ''}
`,
);

export const KdsButton = styled(Button)<{
  size?: 'big' | 'default';
  $marginRight?: keyof Spacing;
  $marginBottom?: keyof Spacing;
  $loading: boolean;
}>(
  ({ size = 'default', $marginRight, $marginBottom, $loading }) => `
  ${alignSelf('center')}
  ${radius('xxxxl')}
  ${$marginRight ? marginR($marginRight) : ''}
  ${$marginBottom ? marginB($marginBottom) : ''}
  height: ${size === 'big' ? 64 : 50}px;
  p {
    ${textAlign('center')}
    ${fontSize(size === 'big' ? 24 : 20)}
  }
  ${
    $loading &&
    `:before{
      ${radius('half')}
      ${marginR('xs')}
      ${marginB(5)}
      content: '';
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      border-top: 4px solid white;
      border-right: 4px solid transparent;
      animation: spinner .4s linear infinite;
    }
    @keyframes spinner {
      to {transform: rotate(360deg);}
    }`
  }
`,
);
