/** Replaces the parameters of the route path with the arguments of the function using regex https://stackoverflow.com/a/52899009 */
export default (path: string, ...args: string[]) => {
  let count = -1;

  return path.replace(/:[a-zA-Z?]+/g, (match) => {
    count += 1;

    return args[count] !== undefined ? args[count] : match;
  });
};
